<template lang='pug'>
	b-modal(:id="id" ref='modal' centered content-class="modal-promotions" header-class="d-flex p-0 border-0 align-items-center" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
		template(#modal-header="{ close }")
			span.b1 {{ title }}
			icon-close.modal-promotions__close(@click="close()")
		span.b3.mb-3(v-html='text')
		.d-flex.justify-content-between
			.btn.btn-border.btn-small.w-100.mr-4(@click="close") {{ $t('promotions.cancel') }}
			.btn.btn-small.w-100.btn-peril(v-if='item' @click='action' :disabled='isloading')
				.loader(v-if='isloading')
				span(v-else) {{ $t('forms.remove') }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import IconClose from "@/components/Common/IconClose";

export default {
	name: 'ModalRemoveIntegration',
	components: {
		IconClose
	},
	props: {
		id: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		},
		text: {
			type: String,
			default: ''
		},
		item: {
			type: Object,
			default: () => (null)
		}
	},

	data: () => ({
		isloading: false
	}),
	computed : {
		...mapGetters({
			isPending		: 'plans/isPending',
		})
	},
	methods : {
		...mapActions({
			
		}),

		action () {
			this.isloading = true;
			this.$emit('onAction')
		},

		close () {
			if ( this.$refs.modal )
				this.$refs.modal.hide();
		},
	},
	watch : {
		item : {
			handler (nV, oV) {
				if ( nV != null && nV.type ) {
					this.isloading = false;
					this.$refs.modal.show();
				}
			}
		}
	}
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.modal-promotions {
	max-width: 360px;
	padding: 20px;
	margin: 0 auto;

	@include respond-below(sm) {
		border-bottom-left-radius: var(--border-radius-rounded) !important;
		border-bottom-right-radius: var(--border-radius-rounded) !important;
		margin: auto 12px;
	}

	&__close {
		transition: var(--animation-time-short);

		&:hover,
		&:active {
			opacity: 0.7;
		}
	}

	.modal-header {
		margin-bottom: 10px;
	}
}
</style>
