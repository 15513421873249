<template lang='pug'>
	.position-relative.pw-btn-mail
		a.d-flex.justify-content-between.align-items-center.text-decoration-none(
			:href="$t('contacts.general_email')"
			:class="icon"
		)
			span.b3.mr-1 {{ $t('h1.mail') }}
			svg.ml-2(width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg")
				path(d="M10.7129 3.75L15.7507 9L10.7129 14.25" stroke="#52A7F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
				line(x1="14.5488" y1="9.27344" x2="3.24903" y2="9.27344" stroke="#52A7F9" stroke-width="2" stroke-linecap="round")
		span.fontello-icon-copy(@click.stop="$bvToast.show('mail-copy'), copy('mail@paywall.pw')")
		b-toast(id="mail-copy" toaster="b-toaster-top-center") {{ `😊 ${$t('h1.copyMail')}` }}
</template>
<script>

export default {
	name: 'PwBtnMail',
	data: () => ({
		icon: null
	}),
	methods: {
		async copy(text) {
			try {
				await navigator.clipboard.writeText(text);
			} catch (e) {
				console.log(e);
			}
		},
	}
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.pw-btn-mail {
	padding: 14px 16px 14px 54px;
	background-color: var(--light-primary);
	border-radius: var(--border-radius-rounded);
	transition: var(--animation-time-short);

	a{
		color: var(--main-text-color) !important;
	}

	&:hover,
	&:active {
		background-color: var(--foreground-color);
		box-shadow: var(--box-shadow-hover);
		color: var(--main-text-color) !important;
	}

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 20px;
		width: 24px;
		height: 24px;
		background-color: var(--brand-primary);
		border-radius: 50%;
		transform: translateY(-50%);
	}

	&::before {
		content: '';
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 24px;
		width: 15px;
		height: 15px;
		mask-image: url('/assets/img/icons/mail.svg');
		background-color: var(--foreground-color);
		mask-size: cover;
		transform: translateY(-50%);
	}

	@include respond-below(sm) {
		padding: 14px 16px 14px 54px;
	}

	.fontello-icon-copy {
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 108px;
		font-size: 20px;
		color: var(--brand-primary);
		transform: translateY(-50%);
		transition: var(--animation-time-short);
		cursor: pointer;

		&:hover,
		&:active {
			color: var(--primary-dark-text-color);
		}
	}
}
</style>
