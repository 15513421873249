<template lang='pug'>
	b-card.b1.foreground-color.info(no-body)
		.d-flex.align-items-center.mb-1.text-nowrap
			router-link.text-grey(to="/tutorials" target="_blank") {{ `${$t('h1.tutorials')}&nbsp&nbsp` }}
			i.fontello.fontello-icon-forward-arrow.text-grey.info__arrow(v-if="title")
			span(v-if="title") {{ `&nbsp;&nbsp${title}` }}
		.d-flex.flex-column.flex-md-row.justify-content-between.flex-wrap
				pw-btn-link.mt-3.info__item(v-for="(item, i) in data" :key="i" :text="item.text" :link="item.link" :blank="item.blank")
</template>

<script>
import PwBtnLink from '@/components/Buttons/PwBtnLink';

export default {
	name: 'Info',
	components: {
		PwBtnLink
	},
	props: {
		data: {
			default: () => ([]),
			type: Array
		},
		title: {
			default: () => "",
			type: String
		}
	}
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.info {
	padding: 20px;

	@include respond-below(sm) {
		padding: 16px;
	}

	&__arrow {
		font-size: 12px;
	}

	&__item {
		width: calc(33.33% - 16px);

		@include respond-below(sm) {
			width: 100%;
		}
	}
}
</style>
